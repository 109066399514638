<template>
    <div class="text-center">
        <div  @click="openInputFile" style='width:90%;height:40px;background-color:lightgrey;border:3px dashed grey;justify-content:center' class='mx-auto d-flex'>
            
            <div v-if='!loading'><v-icon size="30" color='white'>add_photo_alternate</v-icon></div>
            <div v-else><v-progress-circular indeterminate color="orange"></v-progress-circular></div>
            
            <div  class="ml-2 mt-1">Ajouter {{this.document_libelle}}</div>
        </div>
        <!-- Affichage des photos présentes et ajoutées -->
        <div  v-for="(file, index) in filesRefreshed" :ref="'file-info-' + index" v-bind:key="index">
            <div style='width:90%;height:40px;background-color:lightgrey;border:3px solid grey;border-radius:20px' class='mx-auto mt-2 pt-1' @click="getFile(index)" v-if='file.filename.indexOf("signature") == -1' >{{file.filename}}</div>
        </div>

        <input ref="file" type="file" @change="fileUpload" hidden>

        <div  >
                    
        </div>
    </div>
</template>

<script>
    export default {
        name: "CxPhotoGalerie", 
        data () {
            return {
                files : [],
                loading : false
            }
        },
        props: {
            model: {
                type: String,
                default: ''
            },
            id: {
                type: Number,
                default: 0
            },
            type: {
                type: String,
                default: ''
            },
            document_libelle: {
                type: String,
                default: 'une photo'
            }
        },
        mounted(){
            this.checkFiles()
        },
        computed: {
            filesRefreshed() {return this.files}
        },
        methods: {
            openInputFile () {
                this.$refs.file.click()
            },
            fileUpload (event) {
                
                let data = {
                            inter_id : this.id,
                            files: event.target.files[0],
                            type: this.type,
                            filename: this.getNomenclaturePictureName()
                        }
                this.loading = true
                this.$services.request.post('/api/phizz/file-management/' + this.model + '/' + this.id + '/upload-file',data).then( () => {
                    this.$services.powerLib.notification.showSnackbar({
                                message : 'La photo a bien été uploadée',
                                type : 'success'
                    })
                    this.checkFiles()
                    this.loading = false
                }).catch(()=>{
                    this.$services.powerLib.notification.showSnackbar({
                                message : 'Une erreur est survenue lors du téléchargement',
                                type : 'error'
                    })
                    this.loading = false
                })
            },
            getFile(index){
                this.$services.request.get('/api/phizz/file-management/' + this.model +'/' + this.id +'/get-file?filename=' + this.files[index].basename).then((response) => {
                window.open(response.fileUrl)
            })
            },
            checkFiles(){
                this.$services.request.get('/api/phizz/file-management/' + this.model +'/' + this.id +'/get-files-metadata').then((response) => {
                this.files = response
            })
            },
            getNomenclaturePictureName(){
                return this.id + '_' + Date.now() + '_' + this.type + '.png'
            }
        }
    }
</script>